<template>
    <section-block>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2 text-md-center">
                    <h1 class="text-responsive">
                        Coming 2022
                    </h1>
                    <h5 class="text-responsive text-responsive fw-light">
                        We’re constantly updating the Aven card with new & improved features. Stay tuned for more :)
                    </h5>
                </div>
            </div>
            <div class="row mt-3 gy-5">
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Phone.svg"
                        height="32"
                        class="mb-1"
                        alt="Wallet"
                    >
                    <p>
                        <strong>Apple Pay & Wallet Integration</strong><br>
                        Coming soon - until then you’ll have to still pull out the beautiful card and swipe/insert it at VISA terminals. But coming soon!
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Gift.svg"
                        height="32"
                        class="mb-1"
                        alt="Rewards"
                    >
                    <p>
                        <strong>Crypto Rewards</strong><br>
                        We’ll be integrating the ability to get rewarded for your transactions directly in BTC or ETH.
                    </p>
                </div>
            </div>
            <div class="row mt-1 mt-md-3 gy-4">
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Up.svg"
                        height="32"
                        class="mb-1"
                        alt="LTV"
                    >
                    <p>
                        <strong>Higher LTV, Higher APRs</strong><br>
                        Want to go beyond 35% LTV? we’ll be be building our Prime product to help you go upto higher LTVs
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Coin.svg"
                        height="32"
                        class="mb-1"
                        alt="HODL"
                    >
                    <p>
                        <strong>More Coins</strong><br>
                        Just getting started. We’ll be working on adding more currencies over time. Feel free to reach out to us and give us feedback on which coin you’d like to see us support.
                    </p>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'

    export default {
        name: 'CommonQuestionsSection',
        components: {
            'section-block': SectionBlock,
        },
    }
</script>
