<template>
    <section-block>
        <div class="container">
            <div class="row mt-5 mt-md-9 align-items-center">
                <div class="col-12 col-md-7 order-md-1">
                    <h1 class="text-responsive mb-5 mb-md-7">
                        How it works
                    </h1>
                    <div>
                        <div class="d-flex">
                            <h5 class="me-2 me-md-3">
                                1.
                            </h5>
                            <div>
                                <h5 class="mb-0 mb-md-1">
                                    5 Minute Signup
                                </h5>
                                <p>Answer a few simple questions to quickly confirm your identity. <strong>Does not affect your credit score.</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div class="d-flex">
                            <h5 class="me-2 me-md-3">
                                2.
                            </h5>
                            <div>
                                <h5 class="mb-0 mb-md-1">
                                    Hold Crypto. Get Credit.
                                </h5>
                                <p>
                                    Securely deposit crypto into the Aven Wallet for instant access with the Aven Visa Credit Card or deposit cash-out directly to your bank.<sup><a href="#footnote_1">1</a></sup>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div class="d-flex">
                            <h5 class="me-2 me-md-3">
                                3.
                            </h5>
                            <div>
                                <h5 class="mb-0 mb-md-1">
                                    Simple, Flexible Payback
                                </h5>
                                <p>
                                    Only pay for what you use and pay back flexibly starting at 1% min monthly payment, just like any other credit card. No prepayment fees, no origination fees, no
                                    gotchas.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 order-md-0 mt-3 mt-md-0">
                    <video
                        autoplay
                        muted
                        loop
                        class="img-fluid"
                    >
                        <source
                            src="@/assets/images/pages/marketing/crypto/CryptoLP_SignUp_7.mp4"
                            type="video/mp4"
                        >
                        <track
                            id="track1"
                            kind="captions"
                            srclang="en"
                            src="@/assets/images/pages/marketing/crypto/subtitles-en.vtt"
                            label="English"
                            default=""
                        >

                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            </div>
            <div
                v-show="btcPrice"
                class="row mt-7"
            >
                <div class="col-12 col-md-6 offset-md-3 text-center">
                    <h5 class="text-responsive fw-light">
                        Line Of Credit
                    </h5>
                    <label
                        class="heading-1 fw-bold"
                        for="slider"
                    >{{ valueToDollar }}</label>
                    <div>
                        <input
                            ref="slider"
                            id="slider"
                            class="slider"
                            name="slider"
                            type="range"
                            :min="min"
                            :max="maxLineSizeCrypto"
                            step="100"
                            :value="value"
                            @input="handleSlide"
                        >
                    </div>
                    <h5 class="text-responsive fw-light mt-4">
                        Collateral Required
                    </h5>
                    <div class="mt-1">
                        <div class="col-12 d-flex justify-content-between">
                            <div class="d-flex align-items-center">
                                <img
                                    src="@/assets/images/pages/marketing/crypto/btc.png"
                                    class="me-2"
                                    width="48"
                                    alt="BTC"
                                >
                                <h5 class="mb-0 fw-light">
                                    BTC
                                </h5>
                            </div>
                            <h5 class="d-flex align-items-center mb-0">
                                {{ btcCollateral }}
                            </h5>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                        You can get a line of credit up to 35% of the value of your collateral up to a {{ toFormattedUSDStripTrailingZeroCents(this.maxLineSizeCrypto) }}
                    </div>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    import { getCryptoPrice } from '@/services/api'
    import { MAX_LINE_SIZE_CRYPTO } from '@/utils/constants'
    import format from '@/mixins/format'

    export default {
        name: 'HowItWorksSection',
        components: { SectionBlock },
        mixins: [format],
        data: function () {
            return {
                value: 50000,
                min: 500,
                btcCollateral: 1.31,
                btcPrice: null,
                maxLineSizeCrypto: MAX_LINE_SIZE_CRYPTO,
            }
        },
        mounted: function () {
            this.generateBackground()
            this.getCryptoPrice()
        },
        computed: {
            valueToDollar() {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                })
                return formatter.format(this.value)
            },
        },
        methods: {
            handleSlide: function () {
                this.value = this.$refs.slider.value
                this.generateBackground()
                this.calculateValues()
            },

            generateBackground: function () {
                if (this.$refs.slider.value === this.min) {
                    return
                }

                let percentage = ((this.$refs.slider.value - this.min) / (this.maxLineSizeCrypto - this.min)) * 100
                this.$refs.slider.style = 'background: linear-gradient(to right, #D5208E, #D5208E ' + percentage + '%, #293036 ' + percentage + '%, #293036 100%)'
            },

            getCryptoPrice: async function () {
                const cryptoPrice = await getCryptoPrice()
                this.btcPrice = cryptoPrice.data.payload.prices[0].report.bid
                this.calculateValues()
            },

            calculateValues: function () {
                // Desired Line Size / (CLTV * Current BTC Price)
                this.btcCollateral = (this.value / (0.35 * this.btcPrice)).toFixed(4)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .slider[type='range'] {
        width: 100%;
        -webkit-appearance: none;
        height: 13px;
        border-radius: 6px;
        background: #f1f1f1;
        outline: none;
        padding: 0;
        margin: 0;
    }

    /* custom thumb */
    .slider[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #ffffff;
        border: #000 4px solid;
        cursor: pointer;
        -webkit-transition: background 0.15s ease-in-out;
        transition: background 0.15s ease-in-out;
    }

    .slider[type='range']::-webkit-slider-thumb:hover {
        border-color: #d5208e;
    }

    .slider[type='range']::-moz-range-thumb {
        width: 32px;
        height: 32px;
        border: 0;
        border-radius: 50%;
        background: #ffffff;
        border: #000 4px solid;
        cursor: pointer;
        -webkit-transition: background 0.15s ease-in-out;
        transition: background 0.15s ease-in-out;
    }

    .slider[type='range']::-moz-range-thumb:hover {
        border-color: #d5208e;
    }

    /* remove border */
    .slider::-moz-focus-inner,
    input::-moz-focus-outer {
        border: 0;
    }
</style>
