<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6">
                    <h2 class="text-responsive mb-2 mb-md-3">
                        Tell a friend.<br>
                        Get $15 BTC.
                    </h2>
                    <h5 class="text-responsive fw-light">
                        You get $15 BTC and they get $15 BTC anytime someone uses your exclusive referral code to get an Aven Crypto Card. <sup><a href="#footnote_2">2</a></sup>
                    </h5>
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0">
                    <img
                        src="@/assets/images/pages/marketing/crypto/TellAFriend@2x.jpg"
                        class="img-fluid"
                        alt="100% Online"
                    >
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    export default {
        name: 'TellAFriendSection',
        components: { SectionBlock },
    }
</script>

<style scoped></style>
