<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6">
                    <h2 class="text-responsive">
                        Don’t sell.<br>
                        Hodl & spend.
                    </h2>
                    <h5 class="fw-light text-responsive">
                        Easily leverage your crypto assets to get access to a super low-cost Visa card. Only use what you need, when you need.
                    </h5>
                </div>
                <div class="col-12 col-md-6">
                    <img
                        class="img-fluid"
                        src="@/assets/images/pages/marketing/crypto/Card@2x.jpg"
                        alt="Aven Crypto Card"
                    >
                </div>
            </div>
            <div class="row mt-1 mt-md-3 gy-5">
                <div class="col-12 col-md-4">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Cash.svg"
                        height="32"
                        class="mb-1"
                        alt="1.5% Unlimited Cash Back"
                    >
                    <p>
                        <strong>Simple Rate + 1.5% Cashback</strong><sup><a href="#footnote_1">1</a></sup><br>
                        One low rate of 6.99% variable APR plus unlimited 1.5% cashback on every purchase – you earn every time you spend. Buy anything from donuts to cars to NFTs.
                    </p>
                </div>
                <div class="col-12 col-md-4">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Diamond.svg"
                        height="32"
                        class="mb-1"
                        alt="Diamond Hands"
                    >
                    <p>
                        <strong>Don’t Sell. Hold & Spend</strong><br>
                        Use your crypto assets to make purchases anywhere that VISA is accepted – without selling. You can also send cash directly to your bank.
                    </p>
                </div>
                <div class="col-12 col-md-4">
                    <img
                        src="@/assets/images/pages/marketing/crypto/BankAccount.svg"
                        height="32"
                        class="mb-1"
                        alt="Save On Taxes"
                    >
                    <p>
                        <strong>Save on Taxes</strong><br>
                        Selling crypto can result in a taxable gain or loss. Instead, you could save on taxes with by spending without selling. Consult a tax advisor.
                    </p>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'

    export default {
        name: 'WhatIsItSection',
        components: { SectionBlock },
    }
</script>
