<template>
    <form-container
        :id="idPrefix"
        :data-testid="idPrefix"
        ref="form"
        @on-submit="onSubmit"
        @onError="onError"
    >
        <div class="mb-2">
            <form-field
                v-if="codeRequired"
                v-model="sanitizedInviteCode"
                :data-testid="`${idPrefix}-invite-code-input`"
                name="code"
                input-classes="text-uppercase"
                validation-rules="required|min:6"
                placeholder="Invite Code"
                label="Invite Code"
                input-link-label="Forgot?"
                :input-link-to="forgotCodeLink"
                input-link-event="click_link_input_forgot_invite"
            />
        </div>
        <div class="mb-2">
            <form-field-phone
                :data-testid="`${idPrefix}-phone-number-input`"
                name="phone"
                ref="phoneField"
                placeholder="Mobile Number"
                label="Mobile Number"
                v-model="phone"
            />
        </div>
        <form-button
            button-classes="inverse"
            :label="ctaText"
            event-name="click_crypto_button_submit_origination_invite_form"
            :event-props="{ codeRequired }"
            :submitting="submitting"
        />
        <p
            v-if="!codeRequired && showMailOfferLink"
            class="mt-2"
        />
        <div class="mt-2 mt-md-3 text-center text-md-start">
            <p
                class="fw-bold small mb-0"
                v-show="showSafeCreditScore"
                v-html="$t('pages.marketing.landing.safeCreditScore')"
            />
            <p
                class="small text-md-start text-muted text-md-start"
                v-html="$t('pages.marketing.landing.phoneLegalText')"
            />
        </div>
    </form-container>
</template>

<script>
    import FormFieldPhone from '@/components/base/FormFieldPhone'
    import FormField from '@/components/base/FormField'
    import FormButton from '@/components/base/FormButton'
    import FormContainer from '@/components/base/FormContainer'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { marketingPagePaths } from '@/routes/marketingRoutes'
    import { checkPathsMatch } from '@/flow/flowUtility'
    import authMixin from '@/mixins/authMixin'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import assert from 'assert'
    import { logger } from '@/utils/logger'

    export default {
        name: 'OriginationInviteForm',
        components: {
            'form-field-phone': FormFieldPhone,
            'form-field': FormField,
            'form-button': FormButton,
            'form-container': FormContainer,
        },
        mixins: [authMixin],
        props: {
            codeRequired: {
                type: Boolean,
                required: true,
            },
            showForgotCodeOption: {
                type: Boolean,
                default: true,
            },
            forgotCodeLink: {
                type: String,
                default: 'join',
            },
            mailOfferLink: {
                type: Object,
                default() {
                    return { path: '/' }
                },
            },
            ctaText: {
                type: String,
                default: 'Get Started',
            },
            showSafeCreditScore: {
                type: Boolean,
                default: false,
            },
            showMailOfferLink: {
                type: Boolean,
                default: true,
            },
            idPrefix: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loading: false, // needed for assert in authMixin
                submitting: false,
                phone: appSessionStorage.getItem(localStorageKey.phoneNumber) || '',
                code: appSessionStorage.getItem(localStorageKey.inviteCode) || appSessionStorage.getItem(localStorageKey.pifInviteCode) || '',
                options: {
                    phone: true,
                    phoneRegionCode: 'US',
                },
                originationLandingPage: marketingPagePaths.LANDING,
                originationJoinLandingPage: marketingPagePaths.LANDING_JOIN,
            }
        },
        computed: {
            sanitizedInviteCode: {
                get: function () {
                    return this.code
                },
                set: function (userEnteredInviteCode) {
                    const userEnteredInviteCodeStr = typeof userEnteredInviteCode === 'string' ? userEnteredInviteCode : ''
                    const noWhitespaceInviteCode = userEnteredInviteCodeStr.replaceAll(/\s/g, '')
                    this.code = noWhitespaceInviteCode
                    return this.code
                },
            },
        },
        methods: {
            onSubmit: async function () {
                if (this.submitting) {
                    return
                }
                this.submitting = true

                try {
                    // Check if form is valid and not already submitting
                    const isValid = await this.$refs?.form?.$refs?.observer?.validate()
                    if (!isValid) {
                        this.submitting = false
                        return
                    }

                    const data = {}
                    if (this.phone) {
                        data.phoneNumber = this.phone
                    }
                    // Only if a code is required on this form, and a code has been provided by the applicant, will
                    // we overwrite session storage with that code. Otherwise whatever inviteCode/pifInviteCode was
                    // stored prior, will be removed because the applicant took explicit action to remove it by
                    // clicking the "Forgot?" button.
                    if (this.codeRequired && this.code) {
                        if (checkPathsMatch(this.$router.currentRoute.path, marketingPagePaths.PIF_LANDING)) {
                            data.pifInviteCode = this.code.toUpperCase()
                        } else {
                            data.inviteCode = this.code.toUpperCase()
                        }
                    }
                    // If success
                    this.overwriteSessionStorageWithCurrentData(data)
                    const error = await this.setupAuth()
                    if (error) {
                        this.onError()
                    } else {
                        await this.$router.push(getNextRoute(this.$router))
                    }
                } finally {
                    this.submitting = false
                }
            },
            onError() {
                if (this.errorMessage) {
                    this.$logEvent('error_message', { message: this.errorMessage })
                    switch (this.errorField) {
                        case 'phone':
                            this.applyErrorOnPhoneField(this.errorMessage)
                            break
                        case 'code':
                            this.applyErrorOnInviteCodeField(this.errorMessage)
                            break
                        default:
                            this.applyGeneralError(this.errorMessage)
                    }
                }
                this.submitting = false
            },
            applyGeneralError(errorMessage) {
                this.$refs?.form?.applyGeneralError(errorMessage)
            },
            applyErrorOnPhoneField(errorMessage) {
                this.$refs?.form?.applyErrorOnField({ phone: errorMessage })
            },
            applyErrorOnInviteCodeField(errorMessage) {
                this.$refs?.form?.applyErrorOnField({ code: errorMessage })
            },
            overwriteSessionStorageWithCurrentData(data) {
                logger.info(`overwriteSessionStorageWithCurrentData: ${JSON.stringify(data)}`)
                appSessionStorage.removeItem(localStorageKey.phoneNumber)
                appSessionStorage.removeItem(localStorageKey.inviteCode)
                appSessionStorage.removeItem(localStorageKey.pifInviteCode)
                for (const [key, value] of Object.entries(data)) {
                    switch (key) {
                        case 'phoneNumber':
                            logger.info(`overwriteSessionStorageWithCurrentData setting phoneNumber to ${value}`)
                            appSessionStorage.setItem(localStorageKey.phoneNumber, value)
                            break
                        case 'inviteCode':
                            if (this.codeRequired) {
                                logger.info(`overwriteSessionStorageWithCurrentData setting inviteCode to ${value}`)
                                appSessionStorage.setItem(localStorageKey.inviteCode, value)
                            } else {
                                logger.info(`overwriteSessionStorageWithCurrentData inviteCode not required, so not setting to ${value}`)
                            }
                            break
                        case 'pifInviteCode':
                            if (this.codeRequired) {
                                logger.info(`overwriteSessionStorageWithCurrentData setting pifInviteCode to ${value}`)
                                appSessionStorage.setItem(localStorageKey.pifInviteCode, value)
                            } else {
                                logger.info(`overwriteSessionStorageWithCurrentData pifInviteCode not required, so not setting to ${value}`)
                            }
                            break
                        default:
                            assert(false, 'unexpected key in InviteForm success event payload')
                    }
                }
                logger.info(`overwriteSessionStorageWithCurrentData setting currentFlow to ${Flows.origination}`)
                appSessionStorage.setItem(localStorageKey.currentFlow, Flows.origination)
            },
        },
    }
</script>
