<template>
    <section-block>
        <div class="container">
            <div class="row">
                <div class="col-12 text-md-center">
                    <h1 class="text-responsive mb-2">
                        Common questions
                    </h1>
                </div>
            </div>
            <div class="row mt-1 mt-md-3 gy-5">
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Security.svg"
                        height="32"
                        class="mb-1"
                        alt="Security"
                    >
                    <p>
                        <strong>Is it secure?</strong><br>
                        Best-in-class security, compliance, and infrastructure with BitGo. Your crypto is stored using state of the art multi-signature security and 3-key management with SOC 2 Type 2
                        compliance.
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Down.svg"
                        height="32"
                        class="mb-1"
                        alt="Value"
                    >
                    <p>
                        <strong>What happens if crypto values drops?</strong><br>
                        If the value of your deposited crypto drops and your balance exceeds more than 65% of the crypto value, we’ll ask you to deposit more or make a payment to bring your balance to
                        below 65% of the deposited crypto value. If it exceeds 85%, we will immediately make a payment to your card to get it to 65% by selling some of your crypto.
                    </p>
                </div>
            </div>
            <div class="row mt-1 mt-md-3 gy-5">
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Calculator.svg"
                        height="32"
                        class="mb-1"
                        alt="Limit"
                    >
                    <p>
                        <strong>How is my limit calculated?</strong><br>
                        Your limit is based on the amount of crypto you hold into your Aven wallet. You can get a line of credit up to 35% of the value of your collateral up to a
                        {{ toFormattedUSDStripTrailingZeroCents(this.maxLineSizeCrypto) }}.
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/crypto/Help.svg"
                        height="32"
                        class="mb-1"
                        alt="HODL"
                    >
                    <p>
                        <strong>What does HODL mean?</strong><br>
                        HODL originated as a misspelling of the word “hold.” Often used as a phrase in the crypto world to mean “not selling”.
                    </p>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    import { MAX_LINE_SIZE_CRYPTO } from '@/utils/constants'
    import format from '@/mixins/format'

    export default {
        name: 'CommonQuestionsSection',
        components: {
            'section-block': SectionBlock,
        },
        mixins: [format],
        data: function () {
            return {
                maxLineSizeCrypto: MAX_LINE_SIZE_CRYPTO,
            }
        },
    }
</script>
